@import "./colors";
@import "./quiz-form-questions.scss";

.quiz-form-options-radio-input {
  &-div {
    display: flex;
    flex-direction: column;
  }
  &-item {
    display: flex;
    align-items: center;
    border: 1px solid $grey-bg-no-text;
    margin-right:60px;
    background-color: $green-construcia;
    margin-bottom: 10px;
    padding: 15px;
    &:hover{
      border: 1px solid $green;
    }
    &-pressed {
      background-color: $orange-construcia;
      border: 1px solid $green;
    }
  }
  &-label{
    margin: 0px 40px;
    cursor: pointer;
    width: 100%;
  }
}
input{
  cursor: pointer;
}
