.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  border-radius: 5px;
  position: fixed;
  background: white;
  color: black;
  width: 750px;
  height: auto;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-text {
  margin: 40px;
  text-align: center;
}
.modal-btns-div {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}
.btn-yellow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 30px;
  border-radius: 30px;
  border: 0px;
  margin: 0px 0px;
  padding: 13px 30px;
  height: 48px;
  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: black;
  background-color: rgb(255, 204, 0);
  &:hover {
    border: 2px solid grey;
    background-color: rgb(255, 204, 0) !important;
  }
}

.btn-red{
  &:hover{
    border: 2px solid grey;
  }
}
