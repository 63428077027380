@import "./colors.scss";
//btns
button {
  cursor: pointer;
}
.btn-primary-l {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 30px;

  background: $accent;
  border-radius: 30px;
  border: 0px;
  margin: 0px 0px;
  padding: 16px 30px;
  width: 149px;
  height: 48px;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  &-pressed {
    color: $accent-variant2;
  }
  &:hover {
    background: $accent-variant2;
  }
  &:disabled {
    background: $grey-bg;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.btn-primary-m {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 30px;

  background: #f6caa7;
  border-radius: 30px;
  border: 0px;
  margin: 0px 0px;
  padding: 13px 30px;
  width: 149px;
  height: 42px;

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: black;
  &-pressed {
    color: black;
  }
  &:hover {
    background: #cfebcb;
    border: 2px solid #cfebcb;
  }
  &:disabled {
    background: $grey-bg;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.btn-accent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 30px;
  box-sizing: border-box;
  border-radius: 30px;
  background-color: #c2e2dd;
  border: 2px solid #c2e2dd;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  padding: 16px 30px;
  width: 149px;
  height: 48px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  &:hover {
    background: #cfebcb;
    border: 2px solid #cfebcb;
  }
}

//btn respuestas

.btn-response-left {
  width: 143px;
  height: 42px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 60px 0px 0px 60px;
  &:active {
    background: $orange-construcia;
  }
  &:hover {
    border: 1px solid $dark-green-construcia;
  }
  &-pressed {
    background: $orange-construcia;
  }
}
.btn-response-center {
  width: 143px;
  height: 42px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  &:active {
    background: $orange-construcia;
  }
  &:hover {
    border: 1px solid $dark-green-construcia;
  }
  &-pressed {
    background: $orange-construcia;
  }
}
.btn-response-right {
  width: 143px;
  height: 42px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 0px 60px 60px 0px;
  &:active {
    background: $orange-construcia;
  }
  &:hover {
    border: 1px solid $dark-green-construcia;
  }
  &-pressed {
    background: $orange-construcia;
  }
}
