@import "./colors.scss";

.login-div,
.home-div,
.quiz-div {
  width: 100%;
  min-height: 100vh;
  color: white;
  background-image: url(../../public/assets/img/bg-login-construcia.png);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.quiz-div{
  background-size:cover;
  background-position:right;
}

.item {
  margin: 20px;
}
.form-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 400px);
  justify-content: center;
  align-items: center;
}
.form-title {
  color: white;
}
.form-line {
  background-color: #2DAB34;
  height: 5px;
  width: 101px;
  border: 0;
}
.form-inputs-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 80px;
}
.form-inputs {
  width: 520px;
  border: 0;
  background-color: transparent;
  padding: 10px 0px;
  color: white !important;
}
.form-label {
  margin-bottom: 20px;
  color: $primary-variant;
}
.form-submit-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.form-submit-btn {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 30px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  color:#2DAB34;
  font-size: 16px !important;
  &:hover{
    color:black;
  }
}
.form-hr {
  width: 570px;
  border: 0;
  border-bottom: 3px solid white;
  margin-bottom: 40px;
  &-success {
    width: 570px;
    border: 0;
    margin-bottom: 40px;
    border-bottom: 3px solid #11f2d7;
  }
  &-error {
    width: 570px;
    border: 0;
    margin-bottom: 40px;
    border-bottom: 3px solid #f6e9a2;
  }
}

.form-icon {
  margin-left: 20px;
}
.btn-microsoft {
  width: 100%;
}
