//colors:
$primary: #201855;
$primary-variant: #a7a1ce;
// $accent: #f21163;
$accent: #F43A38;
$accent-variant2: #F43A38;
// $accent-variant2: #ce004b;
// $accent-variant3: #f7a2c1;
 $accent-variant3: #F43A38;

$accent-variant4: #ffe9f1;
$green: rgb(13,163,152);
$green-variant: #f5f3f9;
$white: #e9fffc;
$grey-bg: #f5f3f9;
$grey-mid: #a09ba1;
$grey-bg-no: #fafafa;
$grey-bg-no-text: #c5c3d2;
$strokes: rgba(0, 0, 0, 0.87);
$yellow: rgb(255,204,0);
$dark-green-construcia: #2DAB34;
$green-construcia: #cfebcb;
$blue-construcia: #c2e2dd;
$orange-construcia: #f6caa7;