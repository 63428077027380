@import './colors.scss';

.groups-div{
  min-width: 310px;
  min-height: 100vh;
  padding-bottom: 40px;
}
.groups-title-div{
  width: 275px;
  padding-left: 20px;
}
.groups-title{
  padding-top: 50px;
  color: white;
}
.subtitle{
  width: 210px;
  margin: 20px 20px;
  padding-bottom: 20px;
  border-bottom: 1px dashed $dark-green-construcia;
}
.empty-div{
  width: 40px;
}
.icon-done{
  padding-top: 10px;
}