.quiz-body{
  display: flex;
  width: 100%;
}
.quiz-groups-div{
  width: 385px;
}
.quiz-questions-div{
  width: 100%;
}
