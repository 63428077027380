@import "./colors.scss";

.navbar-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.navbar-div-white{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white !important;
  color: $primary;
}
.img-logo{
  width: 183px;
  height: 46px;
  margin: 24px 35px;
}
.user-info-div{
  display: flex;
  margin-right: 30px;
  align-items: center;
}
.user-info{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
}