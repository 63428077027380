.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-select-main {
  border-radius: 5px;
  position: fixed;
  background: white;
  color: black;
  width: 600px;
  height: auto;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal-title {
  margin-bottom: 10px;
  text-align: center;
}
.modal-select-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding-top: 30px;
}
.modal-select {
  margin-bottom: 30px;
  padding: 10px;
  width: 300px;
  text-align: center;
  font-size: 14px;
  &-btns-div{
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}
.modal-profile-buttons-div{
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:10px;
}